'use strict';

import '../scss/main.scss';


const loadForms = () => {
    if (document.querySelector('.marke-submit')) {
        import ( /* webpackChunkName: "marke-form-submission" */ './utils/marke-form-submission').then(
            ({ MarkeFormSubmission }) => {
                document.querySelectorAll('.contact-form.marke-submit').forEach((form) => {
                    new MarkeFormSubmission({
                        form: form,
                        successMsg: 'Envio com sucesso! Retornaremos em breve o contato.',
                        errorMsg: 'Ops! Houve um erro no envio do formulário!. Entre em contato conosco por outra maneira.',
                    });
                });
            }
        );
    }
};


if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => {
        loadForms();
    });
} else {
    loadForms();
}